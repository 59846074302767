import { graphql, useStaticQuery } from "gatsby";

export const insertChannelsightScript = () => {
  const whereToBuyButtons = document.getElementsByClassName("cswidget");
  // Inserisci lo script solo se ci sono bottoni "cswidget"
  if (whereToBuyButtons.length) {
    // console.log('Appending Channelsight script');
    const channelsightScript = document.createElement("script");
    channelsightScript.id = `channelsight-script-injected`;
    channelsightScript.setAttribute(
      "src",
      `https://cscoreproweustor.blob.core.windows.net/widget/scripts/cswidget.loader.js`
    );
    channelsightScript.setAttribute("async", "");
    channelsightScript.setAttribute("defer", "");
    document.head.appendChild(channelsightScript);
  }
};

export const removeChannelsightScript = () => {
  // console.log('Removing Channelsight script');
  const csScript = document.getElementById(`channelsight-script-injected`);
  if (csScript) {
    csScript.remove();
  }
  const csWidgetScript = document.getElementById(`cswidgetjs`);
  if (csWidgetScript) {
    csWidgetScript.remove();
  }
};

export const patchWtbCss = () => {
  document.addEventListener("click", (e) => {
    if (e.target.classList.contains("cs_content-buylocally-toggle-map")) {
      const csmodalbox = e.target.closest(".cs_content-buylocally");

      if (csmodalbox.classList.contains("cs_content-buylocally--map-hidden")) {
        csmodalbox.classList.remove("cs_content-buylocally--map-hidden");
      } else {
        csmodalbox.classList.add("cs_content-buylocally--map-hidden");
      }
    }
  });
};

export const closeCsWidget = () => {
  [
    ...document.getElementsByClassName("csWidgetModal"),
    ...document.getElementsByClassName("csWidgetModal-backdrop"),
  ].forEach((item) => item.remove());
  document.body.classList.remove("csWidgetModal-open");
};

const useWhereToBuy = (assetId) => {
  const {
    config: { wtbActive, dataAssetId },
  } = useStaticQuery(graphql`
    {
      config: liferayGeneralConfiguration {
        wtbActive
        dataAssetId
      }
    }
  `);

  return wtbActive && !!wtbActive?.length && !!assetId
    ? assetId
    : wtbActive && !!wtbActive?.length && dataAssetId
    ? dataAssetId
    : undefined;
};

export default useWhereToBuy;
